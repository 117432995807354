<template>
  <section v-view class="wer-sind-wir-teaser part" :class="[ payload.optionen ]">
    <div class="animate reveal image">
      <Image v-view :src="payload.bild" />
    </div>
    <div class="constrain content">
      <div class="row-12">
        <div v-view class="col-12 md:col-5 text animate in-bottom">
          <div class="animate reveal image mobile">
            <Image :src="payload.bild" />
          </div>
          <h2 v-html="payload.text" />
          <Go :to="payload.buttonLink" class="text-btn">
            <span v-html="payload.buttontext" />
            <icon-button>
              <arrow />
            </icon-button>
          </Go>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Arrow from '@/assets/images/arrow.svg?inline';

export default {
  components: { Arrow },
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";
@import "@/assets/styles/_grid.scss";

.wer-sind-wir-teaser {
  position: relative;
  @include responsive('margin-top', px(64), px(280));

  &:last-of-type {
    @include responsive('margin-bottom', px(68), px(370));
  }

  :deep(.image) {
    @include responsive('display', none, block);
    @include responsive('width', 100%, 55vw);
  }

  :deep(.image.mobile) {
    @apply rounded-big;

    margin-bottom: px(18);
    width: 100%;

    @include responsive('display', block, none);
  }
}

.content {
  @include responsive('left', 0, 50%);
  @include responsive('top', 0, 50%);
  @include responsive('position', relative, absolute);
  @include responsive('transform', none, translate(-50%, -50%));
}

@screen md {
  .text {
    margin-left: calc(600% / 12);
  }

  .right .text {
    margin-left: calc(100% / 12);
  }
}

.text-btn {
  @include responsive('margin-left', 10%, auto);
  @include responsive('margin-top', px(17), px(112));

  .right & {
    margin-left: 10%;
  }
}

.reveal {
  width: max-content;
  height: max-content;
  overflow: hidden;
  @apply rounded-big;
  @screen md {
    @apply rounded-r-big;

    .right & {
      @apply rounded-l-big;
      @apply rounded-r-none;
      margin-left: auto;
    }
  }
}
</style>
